import React, { Component } from 'react';
import { Element } from 'react-scroll';
import { css } from 'linaria';

import { theme } from '../../utils/theme';

import Section from '../layout/Section';
import Button from '../Button';
import { Heading } from '../typography';
import Ornament from '../Ornament';
import FormError from '../FormError';
import Link from '../Link';

import darkLogo from '../../images/callstack-dark.svg';

const styles = {
  container: css`
    display: flex;
    flex-flow: column;
    @media (min-width: 768px) {
      flex-flow: row;
    }
    & > div {
      @media (min-width: 768px) {
        width: 50%;
      }
    }
  `,
  info: css`
    padding-right: 15px;
    @media (min-width: 768px) {
      padding-right: 75px;
    }
    ul {
      padding-left: 20px;
      margin-top: 35px;
    }
    li {
      font-size: 18px;
      color: ${theme.colors.nearBlack};
      line-height: 28px;
      margin-bottom: 15px;
    }
  `,
  form: css`
    background: ${theme.colors.primary};
    padding: 30px 40px;
    margin-top: 45px;
    z-index: 2;
    min-height: 430px;
    @media (min-width: 768px) {
      margin-top: unset;
    }
    h2,
    small {
      color: ${theme.colors.white};
    }
    h2 {
      margin-bottom: 30px;
    }
    form {
      display: flex;
      flex-flow: column;
    }
    button {
      margin-top: 30px;
    }
  `,
  input: css`
    font-size: 16px;
    height: 60px;
    line-height: 30px;
    padding: 15px;
    width: 100%;
    &:focus,
    &:active {
      outline: 2px solid ${theme.colors.accent};
    }
  `,
  textarea: css`
    font-size: 16px;
    height: 100px;
    line-height: 30px;
    padding: 15px;
    resize: none;
    &:focus,
    &:active {
      outline: 2px solid ${theme.colors.accent};
    }
  `,
  disclaimer: css`
    margin-top: 20px;
    a {
      color: ${theme.colors.accent};
    }
  `,
  headingStyles: css`
    line-height: 77px;
    margin-bottom: 60px;
    margin-top: 50px;
  `,
  builtBy: css`
    display: flex;
    align-items: center;
    flex-direction: column;

    & p {
      margin: 0 0 10px 0;
      color: #414757;
      line-height: 28px;
      font-size: 16px;
    }
  `,
  section: css`
    min-height: 700px;
    // padding-top: 100px;
    // padding-bottom: 190px;
    position: relative;
  `,
  ornament: css`
    visibility: hidden;
    @media (min-width: 768px) {
      visibility: visible;
    }
  `,
  ornamentTopLeft: css`
    left: 0;
    top: 130px;
  `,
  ornamentTopRight: css`
    right: 10px;
    top: 100px;
  `,
  ornamentBottomLeft: css`
    bottom: 0;
    left: 170px;
  `,
  ornamentBottomRight: css`
    bottom: 200px;
    right: 0;
  `,
  nameInputsRow: css`
    display: flex;
    flex-direction: column; /* row only on bigger screens */
    @media (min-width: 1024px) {
      flex-direction: row;
    }
  `,
  nameInputsCol: css`
    display: flex;
    flex-direction: column; 
    width: 100%;
    @media (min-width: 1024px) {
      &:first-of-type {
        margin-right: 10px;
      }
    }
  `
};

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      message: '',
      lastName: '',
      firstName: '',
      typeOfInquiry: '',
      howDidYouHearAboutUs: '',
      formValid: false,
      showErrors: false,
      submitSuccess: false,
      submitError: false,
      submitInProgress: false
    };

    this.emailRegexp = new RegExp(/\S+@\S+\.\S+/);
  }

  handleFormChange = event => {
    this.setState(
      { [event.target.name]: event.target.value },
      this.validateForm
    );
  };

  validateForm = () => {
    const { email, message } = this.state;

    if (!this.emailRegexp.test(email)) {
      this.setState({ formValid: false });
      return;
    }

    if (message.trim().length < 1) {
      this.setState({ formValid: false });
      return;
    }

    this.setState({ formValid: true });
  };

  getHubSpotUserToken = () => {
    const matches = document.cookie.match(/hubspotutk=(\w)+;/g)
    if (matches !== null && matches.length > 0) {
      return matches[0].replace('hubspotutk=', '').replace(';', '')
    }
    return undefined
  };

  submitForm = data => {
    const apiUrl = this.props.apiUrl
    const hubSpotUserToken = this.getHubSpotUserToken()
    return fetch(`${apiUrl}/hire-us`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        ...data,
        pageUri: document.location.href,
        hubSpotUserToken,
      })
    }).then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    });
  }

  handleFormSubmit = event => {
    event.preventDefault();
    this.setState({ submitSuccess: false, submitError: false });

    if (!this.state.formValid) {
      this.setState({ showErrors: true });
      return;
    }
    this.setState({ submitInProgress: true });

    const { 
      email, 
      message, 
      firstName, 
      lastName, 
      typeOfInquiry,
      howDidYouHearAboutUs,
    } = this.state;

    const data = {
      email,
      message,
      lastName,
      firstName,
      typeOfInquiry,
      howDidYouHearAboutUs
    };

    this.submitForm(data)
      .then(() => {
        this.setState({ submitSuccess: true, submitInProgress: false });
      })
      .catch(error => {
        this.setState({ submitError: true, submitInProgress: false });
      });
  };

  notEmpty = (string) => string.trim().length < 1

  render() {
    const {
      email,
      message,
      firstName,
      lastName,
      typeOfInquiry,
      howDidYouHearAboutUs,
      submitSuccess,
      submitError,
      submitInProgress,
      showErrors
    } = this.state;

    return (
      <Section outerStyles={styles.section}>
        <Ornament
          styles={`${styles.ornament} ${styles.ornamentTopLeft}`}
          rotate={45}
        />
        <Ornament
          styles={`${styles.ornament} ${styles.ornamentTopRight}`}
          rotate={-45}
        />
        <Ornament
          styles={`${styles.ornament} ${styles.ornamentBottomLeft}`}
          rotate={-45}
        />
        <Ornament
          styles={`${styles.ornament} ${styles.ornamentBottomRight}`}
          rotate={-135}
        />
        <div>
          <div className={styles.builtBy}>
            <p>
              Built with{' '}
              <span role="img" aria-label="love">
                {' '}
                ❤️{' '}
              </span>
              by
            </p>
            <Link to="https://callstack.com">
              <img src={darkLogo} alt="Callstack" />
            </Link>
          </div>
          <Heading className={styles.headingStyles}>
            Need more?
            <br />
            <span>No problem!</span>
          </Heading>
        </div>
        <Element id="contact">
          <div className={styles.container}>
            <div className={styles.info}>
              <h2>Get in touch with us if you want to:</h2>
              <ul>
                <li>
                  Get customized set of components based on React Native Paper
                </li>
                <li>
                  Set up your app skeleton or switch your current UX/app to
                  React Native Paper
                </li>
                <li>
                  Train your team on how to use React Native Paper effectively
                  together with other libraries like react-navigation
                </li>
                <li>Simply, build a beautiful product</li>
              </ul>
            </div>
            <div className={styles.form}>
              <form onSubmit={this.handleFormSubmit}>
                <h2>Contact us!</h2>
                <div className={styles.nameInputsRow}> 
                  <div className={styles.nameInputsCol}>
                    <input
                      type="text"
                      placeholder="Your first name *"
                      className={styles.input}
                      value={firstName}
                      name="firstName"
                      onChange={this.handleFormChange}
                      />
                    <FormError
                      show={showErrors}
                      cond={this.notEmpty(firstName)}
                    >
                      First name must be at least 1 character long
                    </FormError>
                  </div> 
                  <div className={styles.nameInputsCol}>
                    <input
                      type="text"
                      placeholder="Your last name *"
                      className={styles.input}
                      value={lastName}
                      name="lastName"
                      onChange={this.handleFormChange}
                      />
                    <FormError
                      show={showErrors}
                      cond={this.notEmpty(lastName)}
                    >
                      Last Name must be at least 1 character long
                    </FormError>
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Your business e-mail *"
                  className={styles.input}
                  value={email}
                  name="email"
                  onChange={this.handleFormChange}
                />
                <FormError
                  show={showErrors}
                  cond={!this.emailRegexp.test(email)}
                >
                  Please enter a valid email address
                </FormError>
                <select
                  className={styles.input}
                  id="typeOfInquiry"
                  name="typeOfInquiry"
                  value={typeOfInquiry}
                  onChange={this.handleFormChange}
                >
                  <option className="form-input" value="" disabled>
                    Type of Inquiry *
                  </option>
                  <option className="form-input" value="Business">Business</option>
                  <option className="form-input" value="Careers">Careers</option>
                  <option className="form-input" value="Open source">Open source</option>
                  <option className="form-input" value="Other">Other</option>
                </select>
                <FormError show={showErrors} cond={this.notEmpty(typeOfInquiry)}>
                  Please choose your inquiry type
                </FormError>
                <select
                  className={styles.input}
                  id="howDidYouHearAboutUs"
                  name="howDidYouHearAboutUs"
                  value={howDidYouHearAboutUs}
                  onChange={this.handleFormChange}
                >
                  <option className="form-input" value="" disabled>
                    How did you hear about us? *
                  </option>
                  <option className="form-input" value="Google">Google</option>
                  <option className="form-input" value="Social Media">Social Media</option>
                  <option className="form-input" value="Open source">Open source</option>
                  <option className="form-input" value="RN EU Conference">RN EU Conference</option>
                  <option className="form-input" value="From a friend">From a friend</option>
                  <option className="form-input" value="Other">Other</option>
              </select>
              <FormError show={showErrors} cond={this.notEmpty(howDidYouHearAboutUs)}>
                Please choose one of the options
              </FormError>
                <textarea
                  placeholder="Your message *"
                  className={styles.textarea}
                  value={message}
                  name="message"
                  onChange={this.handleFormChange}
                />
                <FormError show={showErrors} cond={this.notEmpty(message)}>
                  Message must be at least 1 character long
                </FormError>
                <small className={styles.disclaimer}>* - field required</small>
                <small className={styles.disclaimer}>
                  I hereby agree for sending me by Callstack.io Sp. z o.o. with
                  seat in Wrocław, by means of electronic communication to the
                  e-mail address indicated by me, commercial information, within
                  the meaning of the Act of 18 July 2002 on the provision of
                  electronic services. For details see our{' '}
                  <Link to="https://callstack.com/privacy-policy/">
                    Privacy Policy
                  </Link>
                  .
                </small>
                <Button
                  dark
                  type="submit"
                  disabled={submitInProgress || submitSuccess}
                >
                  {submitSuccess
                    ? 'Thank you for your message! We’ll get back to you as soon as possible.'
                    : 'Send'}
                </Button>
                <FormError show={submitError} cond={true}>
                  An error occured while trying to send your email, please try
                  again later.
                </FormError>
              </form>
            </div>
          </div>
        </Element>
      </Section>
    );
  }
}

export default Contact;
