import React from 'react';
import { styled } from 'linaria/react';

const Error = styled.small`
  margin: 5px 0;
`;

function FormError({ children, cond, show }) {
  return <Error>{show && cond ? children : null}</Error>;
}

export default FormError;
