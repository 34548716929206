export const theme = {
  colors: {
    black: '#000000',
    nearBlack: '#414757',
    white: '#ffffff',
    grey: '#d8d8d8',
    lightGrey: '#f5f7fa',
    primary: '#6200ee',
    accent: '#00daee'
  },
  headerHeight: 80
};
