import React, { Component } from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';

import Section from '../layout/Section';

import badge from '../../images/material-badge.svg';
import finger from '../../images/finger.svg';
import playIcon from '../../images/icons/play.svg';
import paper1Poster from '../../videos/paper-1-poster.png';
import paper1Mp4 from '../../videos/paper-1.mp4';
import paper1Webm from '../../videos/paper-1.webm';
import paper2Poster from '../../videos/paper-2-poster.png';
import paper2Mp4 from '../../videos/paper-2.mp4';
import paper2Webm from '../../videos/paper-2.webm';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  order: ${({ order }) => order || 1};
  padding-left: 20px;

  @media (min-width: 768px) {
    flex: 1;
    order: unset;
    justify-content: center;
    padding-left: 0;
  }

  @media (max-width: 899px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ColumnInner = styled.div`
  max-width: 400px;

  @media (min-width: 768px) {
    max-width: 300px;
  }

  @media (max-width: 899px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Badge = styled.img`
  font-family: Roboto;
  margin-top: 58px;
  margin-bottom: 30px;
`;

const Tag = styled.h3`
  font-size: 40px;
  border-bottom: 3px solid #fff;
  display: inline-block;
  padding-bottom: 10px;
  margin: 0;
`;

const Header = styled.h2`
  font-size: 30px;
  margin-top: 35px;
`;

const PlayerContainer = styled.div`
  align-items: center;
  background-image: ${({ background }) => `url(${background})`};
  background-size: contain;
  // box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  height: auto;
  margin-top: 45px;
  max-height: 528px; // preserve movie ratio
  max-width: 297px; // preserve movie ratio
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    margin-top: unset;
  }
`;

const PlayerVideo = styled.video`
  width: 100%;
  height: 100%;
`;

const PlayerOverlay = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'initial' : 'none')};
  position: absolute;
  top: 0;
  transition: opacity 0.4s;
  width: 100%;
  border: 3px solid #6200ee;
  box-sizing: border-box;
`;

const PlayerPlay = styled.img`
  cursor: pointer;
  position: absolute;
`;

const Copy = styled.p`
  line-height: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Hint = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 100%;

  & img {
    margin-right: 12px;
  }

  @media (min-width: 768px) {
    width: 400px;
  }
`;

const styles = {
  outer: css`
    // min-height: 1000px;
    @media (min-width: 992px) {
      // padding-top: 100px;
    }
  `
};

class LookAndFeel extends Component {
  constructor(props) {
    super(props);

    this.video1 = React.createRef();
    this.video2 = React.createRef();
    this.state = {
      video1Started: false,
      video2Started: false
    };
  }

  componentDidMount() {
    this.video1.current.addEventListener('ended', this.handleEnd(1));
    this.video2.current.addEventListener('ended', this.handleEnd(2));
  }

  componentWillUnmount() {
    this.video1.current.removeEventListener('ended', this.handleEnd(1));
    this.video2.current.removeEventListener('ended', this.handleEnd(2));
  }

  handleBegin = videoNumber => () => {
    this[`video${videoNumber}`].current.play();
    this.setState(() => ({
      [`video${videoNumber}Playing`]: true
    }));
  };

  handleEnd = videoNumber => () => {
    this.setState(() => ({
      [`video${videoNumber}Playing`]: false
    }));
  };

  render() {
    const { video1Playing, video2Playing } = this.state;

    return (
      <Section dark outerStyles={styles.outer}>
        <Row>
          <Column>
            <div>
              <ColumnInner>
                <Tag>Look</Tag>
                <Header>What makes an app look native? </Header>
                <Copy>
                  Its interface. It is responsive, fast and works reliably on
                  both platforms. When building a React component, you have to
                  style each of them yourself, according to the guidelines of
                  the platform you are targeting. This can be overwhelming and
                  non-trivial to do right.
                </Copy>
                <Badge src={badge} />

                <Hint>
                  <img src={finger} alt="point up emoji" />
                  <span>
                    It's not a real badge, actually, but you can take our word
                    for it ;)
                  </span>
                </Hint>
              </ColumnInner>
            </div>
          </Column>
          <Column>
            <PlayerContainer background={paper1Poster}>
              <PlayerVideo
                muted
                poster={paper1Poster}
                ref={this.video1}
                preload="auto"
              >
                <source src={paper1Webm} type="video/webm" />
                <source src={paper1Mp4} type="video/mp4" />
              </PlayerVideo>
              <PlayerOverlay
                show={!video1Playing}
                onClick={this.handleBegin(1)}
              >
                <PlayerPlay src={playIcon} />
              </PlayerOverlay>
            </PlayerContainer>
          </Column>
        </Row>
        <Row style={{ marginTop: 60 }}>
          <Column order={2}>
            <PlayerContainer background={paper2Poster}>
              <PlayerVideo
                muted
                poster={paper2Poster}
                ref={this.video2}
                preload="auto"
              >
                <source src={paper2Webm} type="video/webm" />
                <source src={paper2Mp4} type="video/mp4" />
              </PlayerVideo>
              <PlayerOverlay
                show={!video2Playing}
                onClick={this.handleBegin(2)}
              >
                <PlayerPlay src={playIcon} />
              </PlayerOverlay>
            </PlayerContainer>
          </Column>
          <Column>
            <ColumnInner>
              <Tag>Feel</Tag>
              <Header>What makes an app feel native?</Header>
              <Copy>
                Snappy interface that behaves just like any other app. It
                supports accessibility standards - in other words, it does
                everything a native app would do. Interactions in React Native
                are easy to do, but getting to 100% polish requires extra
                effort. Implementing exact platform-specific animations, making
                sure it runs under heavy load are just two examples of what we
                have to think when writing first-class interface.
              </Copy>

              <Copy>
                React-native-paper ships with a lot of components and
                interactions that are there to satisfy every single use case you
                might have. See it for yourself.
              </Copy>
            </ColumnInner>
          </Column>
        </Row>
      </Section>
    );
  }
}

export default LookAndFeel;
