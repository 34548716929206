import React from 'react';
import { css } from 'linaria';
import { Element } from 'react-scroll';

import { theme } from '../../utils/theme';
import Link from '../Link';
import Section from '../layout/Section';
import Button from '../Button';

import heroImage from '../../images/hero-image.png';
import heroImage2 from '../../images/hero-image2.png';

import appstore from '../../images/appstore.svg';
import googlePlay from '../../images/google-play.svg';

const styles = {
  wrapper: css`
    position: relative;
    padding-bottom: 0px;
    padding-top: 0px;
    min-height: 700px;
    @media (min-width: 401px) {
      min-height: 420px;
    }
    @media (min-width: 901px) {
      min-height: 700px;
    }
  `,
  container: css`
    display: flex;
    flex-flow: row;
    padding-bottom: 30px;

    @media (min-width: 901px) {
      padding-bottom: 0px;
    }
  `,
  heading: css`
    font-size: 52px;
    line-height: 120%;
    margin-top: 0;
    span {
      color: ${theme.colors.primary};
    }
    margin-bottom: 25px;
  `,
  body: css`
    font-size: 18px;
    color: #414757;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 10px;
  `,
  heroImage: css`
    width: auto;
    position: relative;
    width: 320px;
    height: 670px;
    bottom: -70px;
    left: 30px;
  `,
  heroImage2: css`
    display: none;
    background-image: url(${heroImage2});
    background-size: contain;
    background-repeat: no-repeat;
    overflow-x: hidden;

    @media (min-width: 1200px) {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      max-height: 700px;
      bottom: -140px;
      right: -380px;
    }
  `,
  mobilesContainer: css`
    display: none;

    @media (min-width: 900px) {
      position: relative;
      display: flex;
      flex: 1;
    }
  `,
  row: css`
    display: flex;
    align-items: center;
    margin-top: 30px;
    a {
      margin-right: 20px;
    }
  `,
  githubWidget: css`
    display: block;
    height: 28px;
    margin-bottom: 25px;
    margin-left: 0;
    white-space: nowrap;
  `,
  star: css`
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  `,
  githubLink: css`
    color: #24292e;
    text-decoration: none;
    outline: 0;
  `,
  githubButton: css`
    display: inline-block;
    font-weight: 600;
    vertical-align: bottom;
    cursor: pointer;
    border: 1px solid #c5c9cc;
    border-radius: 0.25em;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 16px;
    height: auto;
    background-color: #eff3f6;
  `,
  darkTriangle: css`
    margin: -5px 0 0 -7px;
    border-width: 5px 5px 5px 0;
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 0;
    height: 0;
    border: solid transparent;
    border-right-color: #c5c9cc;
  `,
  lightTriangle: css`
    margin: -5px 0 0 -6px;
    border-width: 5px 5px 5px 0;
    border-right-color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 0;
    height: 0;
    border: solid transparent;
    border-right-color: #fff;
  `,
  socialCount: css`
    display: inline-block;
    font-weight: 600;
    vertical-align: bottom;
    cursor: pointer;
    border: 1px solid #c5c9cc;
    border-radius: 0.25em;
    height: auto;
    padding: 7px 10px;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    margin-left: 6px;
    background-color: #fff;
  `,
  docsButton: css`
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.grey};
    color: ${theme.colors.black};
  `,
  heroCopy: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 30px;

    @media (min-width: 600px) {
      padding: 75px 35px 0 35px;
    }

    @media (min-width: 900px) {
      max-width: 495px;
    }
  `,
  storeButtonsWrapper: css`
    margin-top: 40px;
  `,
  storeLogo: css`
    height: 42px; 
    margin-right: 20px;
  `
};

function Hero() {
  return (
    <Element id="hero">
      <Section outerStyles={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.heroCopy}>
            <h1 className={styles.heading}>
              Making your React Native apps <span>look and feel native</span>
            </h1>
            <div className={styles.githubWidget}>
              <span className={styles.githubButton}>
                <Link
                  className={styles.githubLink}
                  to="https://github.com/callstack/react-native-paper"
                  aria-label="Star callstack/react-native-paper on GitHub"
                >
                  <span className={styles.star}>
                    <svg
                      version="1.1"
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      className="octicon octicon-star"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M14 6l-4.9-.64L7 1 4.9 5.36 0 6l3.6 3.26L2.67 14 7 11.67 11.33 14l-.93-4.74L14 6z"
                      />
                    </svg>{' '}
                  </span>
                  <span>Star</span>
                </Link>
              </span>
              <span className={styles.socialCount}>
                <b className={styles.darkTriangle} />
                <i className={styles.lightTriangle} />
                <Link
                  className={styles.githubLink}
                  to="https://github.com/callstack/react-native-paper/stargazers"
                  aria-label="3000+ stargazers on GitHub"
                >
                  <span id="paperStarsCount" className={styles.githubLink}>
                    3,065
                  </span>
                </Link>
              </span>
            </div>
            <p className={styles.body}>
              React Native Paper is a high-quality, standard-compliant Material
              Design library that has you covered in all major use-cases.
            </p>
            <div className={styles.row}>
              <Link to="#numbers">
                <Button dark>Learn more</Button>
              </Link>
              <Link to="https://callstack.github.io/react-native-paper/index.html">
                <Button>Docs</Button>
              </Link>
            </div>
            <div className={styles.storeButtonsWrapper}>
              <p className={styles.body}>Try out components in our demo apps:</p>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.callstack.reactnativepaperexample&hl=pl&gl=US">
                  <img
                    src={googlePlay}
                    className={styles.storeLogo}
                    alt="google play store logo"
                  />
                </a>
                <a href="https://apps.apple.com/app/react-native-paper/id1548934513">
                  <img
                    src={appstore}
                    className={styles.storeLogo}
                    alt="appstore logo"
                  />
                </a>
              </div>
            </div>
 
          </div>
          <div className={styles.mobilesContainer}>
            <img
              src={heroImage}
              className={styles.heroImage}
              alt="mobile mockup"
            />
            <div className={styles.heroImage2} />
          </div>
        </div>
      </Section>
    </Element>
  );
}

export default Hero;
