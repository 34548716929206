import React from 'react';
import { View, Image, ScrollView, StyleSheet } from 'react-native';
import {
  TextInput,
  Button,
  Title,
  Provider,
  Paragraph,
  Appbar,
  Snackbar,
  DefaultTheme,
  Text,
  Switch
} from 'react-native-paper';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import { css } from 'linaria';
import ScrollContainer from 'react-indiana-drag-scroll';

import { code, transformCode } from './exampleCode';
import { Heading } from '../typography';
import Ornament from '../Ornament';

import { theme } from '../../utils/theme';
import editorTheme from '../../utils/editorTheme';
import liveIcon from '../../images/icons/live-icon.svg';
import codeExpoImage from '../../images/code-expo.png';

const customStyle = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  box: {
    padding: 15
  }
});

const scope = {
  View,
  TextInput,
  Button,
  Provider,
  Title,
  Paragraph,
  Appbar,
  Snackbar,
  Text,
  ScrollView,
  ScrollContainer,
  DefaultTheme,
  StyleSheet,
  Switch,
  customStyle
};

const Editor = () => (
  <View style={styles.row}>
    <style type="text/css">
      {`
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
        }
        .inputsWithoutOutline{
          overflow-y: auto !important;
          display: flex;
          flex-direction: column
        }
        .inputsWithoutOutline::-webkit-scrollbar {
          display: none !important
        }
        .inputsWithoutOutline input, .inputsWithoutOutline button{
          outline: none !important;
        }
      `}
    </style>
    <LiveProvider
      code={code}
      scope={scope}
      theme={editorTheme}
      transformCode={transformCode}
      language="jsx"
    >
      <View
        style={[
          styles.flexNoResize,
          { flexBasis: '50%', maxWidth: 460, position: 'relative' }
        ]}
      >
        <img src={liveIcon} style={{ alignSelf: 'start' }} alt="" />
        <Heading style={{ textAlign: 'left', marginTop: 30 }}>
          Try it <span>live</span>!
        </Heading>
        <Text
          style={{
            color: theme.colors.nearBlack,
            fontSize: 18,
            lineHeight: 28,
            fontFamily: 'Roboto',
            marginBottom: 50,
            maxWidth: 400
          }}
        >
          Try our live demo and check it out for yourself! When you are done,
          make sure to copy the code and drop it straight into a React Native
          app! Yes, Paper is cross-platform and works on both web and mobile.
        </Text>
        <div style={{ height: 400, overflow: 'auto', maxWidth: 380 }}>
          <LiveEditor
            style={styles.editor}
            padding={25}
            tabSize={2}
            rows={2}
            textareaId="liveEditorArea"
            autoFocus={false}
          />
        </div>
        <Ornament styles={ornamentStyles} rotate={45} small />
      </View>
      <View style={[styles.flexNoResize, styles.previewWrapper]}>
        <Image source={{ uri: codeExpoImage }} style={styles.mobileImg} />
        <LiveError style={styles.error} />
        <View style={styles.screen}>
          <LivePreview style={styles.preview} />
        </View>
      </View>
    </LiveProvider>
  </View>
);

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 50,
    width: '100%'
  },
  editorWrapper: {
    padding: '.8em',
    borderRadius: '.3em',
    borderStyle: 'solid',
    borderWidth: '1pt'
  },
  editor: {
    width: '100%',
    maxWidth: 380,
    backgroundColor: '#202C32',
    outlineColor: '#6200ee',
    caretColor: '#fff',
    // NOTE: styles below should match these in editorTheme
    fontFamily: '"Roboto Mono", monospace',
    fontSize: 16,
    borderRadius: 5
  },
  error: {
    maxWidth: 380,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    color: '#D8000C',
    backgroundColor: '#FFBABA',
    padding: 10,
    width: 342,
    height: 606,
    top: 84,
    left: 19,
    position: 'absolute',
    zIndex: 10
  },
  screen: {
    width: 342,
    height: 606,
    top: 97,
    left: 19,
    position: 'absolute',
    backgroundColor: '#FFF'
  },
  preview: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    border: `1px solid ${theme.primary}`
  },
  mobileImg: {
    width: 380,
    height: 793
  },
  flexNoResize: {
    flexGrow: 0,
    flexShrink: 0
  },
  previewWrapper: {
    position: 'relative',
    bottom: -80
  }
};

const ornamentStyles = css`
  top: 470px;
  left: 410px;
`;

export default Editor;
