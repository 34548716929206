export const code = `<View style={ customStyle.box }>
  <View style={ customStyle.row }>
  <Text>
    Toggle theme
  </Text>
  <Switch
    onValueChange={
      this.toggleTheme
    }
    value={ this.state.darkTheme }
    tintColor='#DEDEDE'
  />
  </View>
  <Title>
    Sign up to our newsletter!
  </Title>
  <Paragraph>
    Get a monthly dose of fresh React Native Paper news straight to your mailbox. Just sign up to our newsletter and enjoy!
  </Paragraph>
  <TextInput
    style={{ marginTop: 15 }}
    label='Outlined input'
    mode='outlined'
  />
  <TextInput
    style={{ marginTop: 15 }}
    label='Flat input'
    mode='flat'
  />
  <Button
    style={{ marginTop: 15 }}
    icon="send"
    mode="contained"
    onPress={ this.toggleSnack }
  >
    Sign me up
  </Button>
</View>
<Snackbar
  visible={ this.state.showSnack }
  onDismiss={ this.toggleSnack }
  action={{
    label: 'Dismiss',
    onPress: () => {
      // Do side magic
    },
  }}
  duration={
    Snackbar.DURATION_LONG
  }
>
  Hey there! I'm a Snackbar.
</Snackbar>`;

export const transformCode = code => {
  if (typeof code === 'string') {
    return `class MyComponent extends React.Component {
      constructor() {
        super();
        this.lightTheme = {
          ...DefaultTheme,
          dark: false,
          colors: {
            ...DefaultTheme.colors,
            background: '#FFF'
          }
        };
        this.darkTheme = {
          ...DefaultTheme,
          dark: true,
          colors: {
            ...DefaultTheme.colors,
            primary: '#64DAC5',
            background: '#202C32',
            text: '#FFF',
            placeholder: '#AAA'
          }
        };
        this.state = {
          darkTheme: false,
          currentTheme: this.lightTheme,
          showSnack: false
        };
        this.toggleSnack = this.toggleSnack.bind(this);
        this.toggleTheme = this.toggleTheme.bind(this);
      }

      toggleSnack() {
        this.setState(prevState => ({ showSnack: !prevState.showSnack }));
      }

      toggleTheme() {
        if (this.state.darkTheme) {
          this.setState({
            darkTheme: false,
            currentTheme: this.lightTheme
          });
        } else {
          this.setState({
            darkTheme: true,
            currentTheme: this.darkTheme
          });
        }
      }

      render() {
        return (
          <Provider theme={this.state.darkTheme ? this.darkTheme : this.lightTheme}>
            <View
              style={{ backgroundColor: this.state.currentTheme.colors.background }}
            >
              <Appbar.Header>
                <Appbar.Content
                  title="react-native-paper"
                  subtitle="Read more in the docs"
                />
                <Text
                  href="https://callstack.github.io/react-native-paper/appbar-header.html"
                  accessibilityRole="link"
                  target="_blank"
                >
                  <Appbar.Action
                    icon="open-in-new"
                    onPress={this.openDocs}
                    color="#FFF"
                  />
                </Text>
              </Appbar.Header>
              <ScrollContainer
                style={{ height: 550, overflow: 'scroll' }}
                className="inputsWithoutOutline"
              >
                ${code}
              </ScrollContainer>
            </View>
          </Provider>
        );
      }
    }
    `;
  }
};
