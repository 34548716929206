import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';

import Section from '../layout/Section';
import Button from '../Button';
import Link from '../Link';

import discord from '../../images/icons/discord.svg';

const sectionOuterOverride = css`
  min-height: 500px;
  @media (max-width: 992px) {
    padding: 60px 15px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  & h2 {
    font-size: 3rem;
    margin-bottom: 16px;
    margin-top: 40px;

    @media (min-width: 768px) {
      font-size: 4rem;
    }

    & .brand {
      color: #6200ee;
    }
  }

  & h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 30px;
    font-weight: 300;
  }

  & p {
    margin-top: 0;
    margin-bottom: 40px;
    font-weight: 300;
    width: 100%;
    line-height: 24px;
    @media (min-width: 768px) {
      width: 450px;
    }
  }

  img {
    width: 120px;
    @media (min-width: 768px) {
      width: 175px;
    }
  }

  text-align: center;
`;

function Discord() {
  return (
    <Section dark outerStyles={sectionOuterOverride}>
      <Content>
        <img src={discord} alt="Discord logo" />
        <h2>We are in Discord</h2>
        <h3>Join us in the #react-native-paper channel</h3>
        <p>
          Community where people discuss and supports others in building
          react-native-paper apps.
        </p>
        <Link to="https://discord.gg/zwR2Cdh">
          <Button dark>Join us on Discord</Button>
        </Link>
      </Content>
    </Section>
  );
}

export default Discord;
